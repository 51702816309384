import React from "react"
import { Typography, makeStyles } from "@material-ui/core"
import { graphql } from "gatsby"
// import Markdown from "markdown-to-jsx"
import showdown from 'showdown'
import SEO from "../components/seo"
// import moment from "moment"

import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
  h1: {
    fontSize: `2.50em`,
    marginBottom: `1.0rem`,
    fontFamily: `Work Sans`,
    fontWeight: `900`
  },
  content: {
    '& h2': { gutterBottom: true, variant: 'h2', fontFamily: `Work Sans`, fontWeight: `600`, fontSize: `1.85em`, },
    '& h3': { gutterBottom: true, variant: 'h3', fontFamily: `Work Sans`, fontWeight: `600`, fontSize: `1.5em`, },
    '& h4': { gutterBottom: true, variant: 'h4', fontFamily: `Work Sans`, fontWeight: `500`, fontSize: `1.25em`, },
    '& p': { paragraph: true, fontFamily: `Spectral`, fontWeight: `300`, fontSize: `1.0em` },
    '& a': { paragraph: true, fontFamily: `Spectral`, fontWeight: `400`, fontSize: `1.0em`, color: `purple` },
    '& li': { paragraph: true, fontFamily: `Spectral`, fontWeight: `300`, fontSize: `1.0em` }
  }
}))

// Styling options to work with Markdown which isn't showing code correctly right now. Using only styles above ^^
// const options = {
//   overrides: {
//     h1: {
//       component: Typography,
//       props: {
//         gutterBottom: true,
//         variant: 'h5',
//       },
//     },
//     h2: { component: Typography, props: { gutterBottom: true, variant: 'h2', fontFamily: `Work Sans` } },
//     h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
//     h4: {
//       component: Typography,
//       props: { gutterBottom: true, variant: 'caption', paragraph: true },
//     },
//     p: { component: Typography, props: { paragraph: true, fontFamily: `serif`, fontSize: `1.0em` } },
//     li: {
//       component: withStyles(styles)(({ classes, ...props }) => (
//         <li className={classes.listItem}>
//           <Typography component="span" {...props} />
//         </li>
//       )),
//     },
//   },
// };


const PortfolioItem = ({data}) => {
  ////console.log(data)
  const converter = new showdown.Converter()
  const item = data.cosmicjsPortfolioItems
  return (
    <div>
      <SEO title={`${item.title}`} lang="en"
          description="Learn about me and find ways to reach me"
          meta={[`Zain`, `Khalid`, `Zain Khalid`, `Software`, `Software Developer`, `Developer`,
          `Full Stack`, `Full`, `Full Stack Developer`, `Android`, `Android Developer`, `Portfolio`,
          `${item.title}`]}
      />
      <Layout>
        <Typography className={useStyles().h1}>{item.title}</Typography>
        {/* <Typography variant="caption">
          {moment(post.metadata.post_date).format(`MMMM Do YYYY`)} • {moment(post.metadata.post_date).fromNow()}
        </Typography>*/}
        {/* <Markdown options={options}>{item.metadata.item_content}</Markdown>  */}
        <div className={useStyles().content} dangerouslySetInnerHTML={{ __html: converter.makeHtml(item.metadata.item_content) }}></div>
      </Layout>
    </div>
  )
}

export default PortfolioItem

export const query = graphql`
query ItemQuery($id: String!) {
  cosmicjsPortfolioItems(id: {eq: $id}) {
    id
    title
    metadata {
      item_content
      item_cover {
        url
      }
      item_duration
      item_end
      item_name
      item_roles
      item_start
      item_tagline
    }
  }
}
`